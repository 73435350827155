import { useState } from 'react';
import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { Auth } from 'aws-amplify';
import { loadCustomAttributes } from '../Helpers/cognitoAttributes';

// Define atoms for each user attribute
export const isBetaAtom = atom(false);
const localeAtom = atom('en-gb');
const zoneInfoAtom = atom('');
const ibAdminCodeAtom = atom('');
const ibTimeOffsetAtom = atom('');
const hasAnalyserAtom = atom(false);
const schoolNameAtom = atom('');
export const isTrialAtom = atom(false);
export const validUntilAtom = atom(null);
export const subscriptionAlertsSeenAtom = atomWithStorage('subscription-alerts-seen', '');

const useUserData = () => {
    const [isBeta, setIsBeta] = useAtom(isBetaAtom);
    const [locale, setLocale] = useAtom(localeAtom);
    const [zoneInfo, setZoneInfo] = useAtom(zoneInfoAtom);
    const [ibAdminCode, setIbAdminCode] = useAtom(ibAdminCodeAtom);
    const [ibTimeOffset, setIbTimeOffset] = useAtom(ibTimeOffsetAtom);
    const [hasAnalyser, setHasAnalyser] = useAtom(hasAnalyserAtom);
    const [schoolName, setSchoolName] = useAtom(schoolNameAtom);
    const [isTrial, setIsTrial] = useAtom(isTrialAtom);
    const [validUntil, setValidUntil] = useAtom(validUntilAtom);
    const [alertsSeen, setAlertsSeen] = useAtom(subscriptionAlertsSeenAtom);
    const [isLoading, setIsLoading] = useState(false);

    const fetchUserData = async () => {
        setIsLoading(true);
        try {
            // console.log('Fetching user data...');
            const user = await Auth.currentAuthenticatedUser();
            const attributes = await loadCustomAttributes();
            const groups = user.signInUserSession.accessToken.payload['cognito:groups'] || [];
            // console.log('User groups:', groups);

            // Check if user is in beta group and update state
            const isUserInBeta = groups.includes('beta');
            // console.log('Is user in beta group:', isUserInBeta);
            setIsBeta(isUserInBeta);

            // Set locale, defaulting to 'en-gb' if not present
            setLocale(attributes.locale || 'en-gb');

            // Set zoneInfo, defaulting to empty string if not present
            setZoneInfo(attributes.zoneinfo || '');

            // Set IB admin code
            setIbAdminCode(attributes.ibAdminCode || '');

            // Set IB time offset
            setIbTimeOffset(attributes.ibTimeOffset || '');

            // Check if user has analyser access
            setHasAnalyser(attributes.hasAnalyser === 'true');

            // Set school name
            setSchoolName(attributes.schoolName || '');

            // Set trial status
            setIsTrial(attributes.isTrial === 'true');

            // Set valid until date if it exists
            if (attributes.validUntil) {
                setValidUntil(new Date(attributes.validUntil));
            }

        } catch (error) {
            console.error('Error fetching user data:', error);
            resetUserData();
        } finally {
            setIsLoading(false);
        }
    };

    const resetUserData = () => {
        setIsBeta(false);
        setLocale('en-gb');
        setZoneInfo('');
        setIbAdminCode('');
        setIbTimeOffset('');
        setHasAnalyser(false);
        setSchoolName('');
        setIsTrial(false);
        setValidUntil(null);
    };

    const checkAnalyserAccess = async () => {
        try {
            const attributes = await loadCustomAttributes();
            return attributes.hasAnalyser === 'true';
        } catch (error) {
            console.error('Error checking analyser access:', error);
            return false;
        }
    };

    return {
        isBeta,
        locale,
        zoneInfo,
        ibAdminCode,
        ibTimeOffset,
        hasAnalyser,
        schoolName,
        isTrial,
        validUntil,
        alertsSeen,
        setAlertsSeen,
        fetchUserData,
        checkAnalyserAccess,
        setIbAdminCode,
        setZoneInfo,
        setIbTimeOffset,
        isLoading
    };
};

export default useUserData;
