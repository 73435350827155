import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Alert, Snackbar, Button } from '@mui/material';
import { differenceInDays } from 'date-fns';
import { loadStripe } from '@stripe/stripe-js';
import { Star, CheckCircle } from '@mui/icons-material';
import { validUntilAtom, subscriptionAlertsSeenAtom, isTrialAtom } from '../../hooks/useUserData';

const STRIPE_PORTAL_URL = 'https://billing.stripe.com/p/login/4gwaHsfdl78sedi288';
const STRIPE_PUBLIC_KEY = 'pk_live_51KagjnH3FcHVT73wfc4zn7gzA1O07DeXwwwPNaH8JxkebxRpNvW1wNhpR9QoQhGt2vzcOq7PyJJa7VA6CTxEKevu00Chv0BHCQ';

export default function SubscriptionAlerts() {
    const [validUntil] = useAtom(validUntilAtom);
    const [isTrial] = useAtom(isTrialAtom);
    const [lastSeenDate, setLastSeenDate] = useAtom(subscriptionAlertsSeenAtom);
    const [currentAlert, setCurrentAlert] = useState(null);

    const isExpired = validUntil && differenceInDays(new Date(validUntil), new Date()) < 0;

    useEffect(() => {
        if (!validUntil || isExpired) return;

        const daysRemaining = differenceInDays(new Date(validUntil), new Date());
        const today = new Date().toDateString();

        // Only show alert if within last 30 days and not shown today
        if (lastSeenDate !== today && daysRemaining <= 30) {
            if (isTrial) {
                setCurrentAlert({
                    message: `Your free trial will expire in ${daysRemaining} days.`,
                    type: 'trial'
                });
            } else {
                setCurrentAlert({
                    message: `Your subscription will expire in ${daysRemaining} days. Make sure your payment method is up to date.`,
                    type: 'subscription'
                });
            }
        }
    }, [validUntil, lastSeenDate, isTrial, isExpired]);

    const handleClose = () => {
        setLastSeenDate(new Date().toDateString());
        setCurrentAlert(null);
    };

    const handleTrialSubscribe = async () => {
        try {
            const stripe = await loadStripe(STRIPE_PUBLIC_KEY);
            const { error } = await stripe.redirectToCheckout({
                mode: 'subscription',
                lineItems: [{
                    price: 'price_1MckzaH3FcHVT73wo7RuLEhf',
                    quantity: 1
                }],
                successUrl: 'https://www.examdisplay.com/success',
                cancelUrl: 'https://www.examdisplay.com/'
            });

            if (error) {
                console.error('Stripe error:', error);
            }
        } catch (error) {
            console.error('Failed to redirect to Stripe:', error);
        }
    };

    const handleSubscriptionManage = () => {
        window.location.href = STRIPE_PORTAL_URL;
        handleClose();
    };

    if (!currentAlert || isExpired) return null;

    return (
        <Snackbar
            open={Boolean(currentAlert)}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
            <Alert
                severity="warning"
                variant="filled"
                onClose={handleClose}
                sx={{ color: 'white' }}
                action={
                    <Button
                        color="inherit"
                        size="small"
                        onClick={isTrial ? handleTrialSubscribe : handleSubscriptionManage}
                        variant="outlined"
                        startIcon={currentAlert.type === 'trial' ? <Star /> : <CheckCircle />}
                    >
                        {currentAlert.type === 'trial' ? 'SUBSCRIBE NOW' : 'CHECK PAYMENT METHOD'}
                    </Button>
                }
            >
                {currentAlert.message}
            </Alert>
        </Snackbar>
    );
}
