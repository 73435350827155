import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { createTheme } from '@mui/material';
import { useMemo } from 'react';

/**
 * Custom hook to manage color mode (light/dark) and create a MUI theme.
 * 
 * This hook uses an atom with storage to persist the color mode in localStorage.
 * It provides a toggle function to switch between light and dark modes and returns
 * a MUI theme object that updates based on the current mode.
 * 
 * @returns {Object} An object containing:
 *  - {Object} colorMode: An object with the current mode and a function to toggle the mode.
 *    - {string} mode: The current color mode ('light' or 'dark').
 *    - {Function} toggleColorMode: Function to toggle between light and dark modes.
 *  - {Object} theme: The MUI theme object configured based on the current color mode.
 */
const colorModeAtom = atomWithStorage('colorMode', 'light');

const useColorMode = () => {
    const [mode, setMode] = useAtom(colorModeAtom);

    const colorMode = useMemo(() => ({
        toggleColorMode: () => {
            setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
        mode,
    }), [mode, setMode]);

    const theme = useMemo(() => createTheme({
        props: {
            MuiAppBar: {
                color: 'transparent',
            },
        },
        components: {
            MuiChip: {
                styleOverrides: {
                    root: {
                        color: '#ffffff',
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    label: {
                        fontWeight: '300',
                        fontSize: '0.8rem',
                    },
                },
            },
            MuiButton: {
                variants: [
                    {
                        props: { size: "extraSmall" },
                        style: { fontSize: '0.6rem', padding: '4px' }
                    }
                ]
            }
        },
        palette: {
            mode: mode,
            primary: {
                main: mode === 'dark' ? '#4a68a1' : '#37517e',
            },
            secondary: {
                main: mode === 'dark' ? '#7a8c97' : '#5d7079',
                dark: mode === 'dark' ? '#5b7079' : '#445A65',
                light: mode === 'dark' ? '#9baab1' : '#7C8A90',
                darker: mode === 'dark' ? '#3b4e59' : '#2D414B'
            },
            tertiary: {
                main: mode === 'dark' ? '#006688' : '#008fc9',
                light: mode === 'dark' ? '#12b6e7' : '#0097c7',
                dark: mode === 'dark' ? '#0083b3' : '#0081ba',
                lighter: mode === 'dark' ? '#1ec8fb' : '#13C1FB'
            },
            quaternary: {
                main: mode === 'dark' ? '#44b564' : '#2ead4b',
                light: mode === 'dark' ? '#5ac67a' : '#008b2b',
                dark: mode === 'dark' ? '#34a24f' : '#069939',
            },
            quinternary: {
                main: mode === 'dark' ? '#dd9500' : '#c97500',
                light: mode === 'dark' ? '#e7a62d' : '#df8700',
                dark: mode === 'dark' ? '#bf6e00' : '#b86700',
                lighter: mode === 'dark' ? '#f6b152' : '#F2A441'
            },
            text: {
                primary: mode === 'dark' ? '#ffffff' : '#3e507b',
                secondary: mode === 'dark' ? '#ffffff' : '#5d7079',
                dark: mode === 'dark' ? '#ffffff' : '#2D414B',
            },
            background: {
                default: mode === 'dark' ? '#1F1F1F' : '#e8eef2',
            },
            warning: {
                main: mode === 'dark' ? '#FF952F' : '#F3722C',
            },
            success: {
                main: mode === 'dark' ? '#1db04a' : '#069939',
            },
            error: {
                main: mode === 'dark' ? '#e63434' : '#cf2929',
                light: mode === 'dark' ? '#FF8383' : '#FF8383',
                lighter: mode === 'dark' ? '#FFA9A9' : '#FFA9A9',
                lightest: mode === 'dark' ? '#FFE8E8' : '#FFE8E8'
            },
            info: {
                main: mode === 'dark' ? '#b9bbbe' : '#a7a9ab',
                lightest: mode === 'dark' ? '#b9bbbe' : '#e5e5e5',
                white: mode === 'dark' ? '#ffffff' : '#ffffff',
            },
            dataGridBackground: {
                main: mode === 'dark' ? '#121212' : '#ffffff',
            },
            dataGridHeader: {
                main: mode === 'dark' ? '#1E2E3A' : '#5d7079',
            },
            alwaysWhite: {
                main: mode === 'dark' ? '#ffffff' : '#ffffff',
            },
            blackWhite: {
                main: mode === 'dark' ? '#121212' : '#ffffff',
            },
        },
        typography: {
            body1: {
                lineHeight: 2,
            }
        }

    }), [mode]);

    return {
        colorMode,
        theme,
    };
};

export default useColorMode;
